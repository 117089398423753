.form {
    padding: 10px;
    font-size: 20px;
}

.save {
    padding: 10px;
    width: 50%;
    font-size: 20px;
    text-align: center;
    display: block;
    margin: auto;
}

.float-container {
    padding: 20px;
}

.float-child-left {
    width: 50%;
    float: left;
    padding-left: 100px;
    text-align: center;
}

.float-child-right {
    width: 50%;
    float: right;
    padding-right: 100px;
    text-align: center;
}

.name {
    text-align-last: center;
    padding-top: 10px;
    color: red;
    font-size: 20px;
}

label {
    display: block;
    text-align: center;
}
