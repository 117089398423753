#chatCon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }

  @media only screen and (min-width: 500px) {
    #chatCon {
      width: 450px;
    }
  }

  @media only screen and (max-width: 499px) {
    #chatCon {
      width: 280px;
    }
  }
  
  .chat-box {
    width: 100%;
    height: 500px;
    border-radius: 25px;
    background-color: #eee;
  }
  
  .message-header {
    background-color: #3ea8ff;
    padding: 15px;
    border-radius: 20px 20px 0 0;
    color: #fff;
    font-size: 20px;
  }
  
  .msg-area {
    overflow-y: auto;
    height: 370px;
    padding: 15px;
  }
  
  .left span {
    display: inline-block;
    font-size: 15px;
    border-radius: 15px;
    padding: 15px;
    background-color: #ffffff;
    font-weight: normal;
  }
  
  .right {
    text-align: right;
  }
  
  .right span {
    display: inline-block;
    font-size: 15px;
    border-radius: 15px;
    padding: 15px;
    background-color: #3ea8ff;
    color: #fff;
    font-weight: normal;
  }
  
  .footer {
    padding: 0 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  .prompt-box {
    border: 1px solid #fff;
    padding: 10px;
    width: 80%;
    border-radius: 15px;
  }
  
  .prompt-box:foucs {
    outline: none;
  }
  
  .send-prompt {
    border: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 25px;
    color: white;
    cursor: pointer;
    border-color: #3ea8ff;
    height: 50px;
    width: 50px;
    background-color: #3ea8ff;
    border-radius: 10px;
  }
  
  .pop {
    text-align: right;
  }

  .openai-button {
    display: flex;
    margin-left: auto;
    margin-right: 0;
    border: 10px;
    font-size: 50px;
    color: white;
    cursor: pointer;
    border-color: #3ea8ff;
    background-color: #3ea8ff;
    height: 80px;
    width: 80px;
    border-radius: 50%;
  }

  .icon {
    margin: auto;
  }
