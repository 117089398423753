.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

td {
  padding: 5px;
}

li {
  padding: 5px;
}

.blue {
  width: 100%;
  background-color: rgb(9, 0, 81);
  height: 50px;
}

.white {
  width: 100%;
  background-color: rgb(250, 253, 254);
  height: 50px;
}
