.background-section {
    margin: 0px;
    padding: 0px;
    position: absolute;
    text-align: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: transparent;
    z-index: 1;
    width: 100%;
    height: 100vh;
}

.home-header {
    font-family: Inter, sans-serif;
    font-size: 32px;
    @media only screen and (max-width: 768px) {
        font-size: 20px;
    }
}

.background {
    padding: 0px;
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    z-index: -1;
}
