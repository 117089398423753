.note-header {
    background-color: #464daf;
    height: 4.5em;
    line-height: 4em;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: fixed;
}

.note-header>ul {
    display: inline;
    margin: 0;
    list-style-type: none;
}

.note-header>ul>li>a {
    font-family: sans-serif;
    font-weight: bold;
    font-size: 2em;
}

.title {
    color: white;
    text-decoration: none;
    cursor: default;
    pointer-events: none;
}

.header-left {
    justify-self: start;
}

.header-right {
    justify-self: end;
    list-style: none;
    padding-right: 15px;
}

.header-right li {
    display: flex;
    align-items: center;
}

.header-right p {
    font-family: sans-serif;
    margin: 0;
    font-size: 1em;
    color: white;
}

.header-right button {
    font-family: sans-serif;
    margin-left: 10px;
}

.flex-container {
    display: flex;
    align-items: center;
}

.mobile-menu {
    position: fixed;
    background-color: #464daf;
    height: 4.5em;
    line-height: 4.5em;
    width: 100%;
    display: flex;
    justify-content: left;
    z-index: 2;
}

.Navbutton {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  width: 70px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333;
  color: #fff;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #666;
  }
}

.mobile-menu .menu-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #333;
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
  min-width: 200px;
  text-align: left;
}

.mobile-menu .menu-items.show {
  display: block;
}

.mobile-menu .menu-items p, .mobile-menu h2 {
  text-decoration: none;
  color: #fff;
}

.mobile-menu .menu-items p {
  cursor: pointer;
  padding-left: 10px;
}

.mobile-menu .menu-items h2 {
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 10px;
  &:hover {
    background-color: #666;
  }
}
