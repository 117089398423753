.minesweeper-header {
    background-color: rgb(247, 198, 2);
    height: 4.5em;
    line-height: 4em;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: fixed;
}

.minesweeper-header>ul {
    display: inline;
    margin: 0;
    list-style-type: none;
}

.minesweeper-header>ul>li {
    margin-right: 1.5em;
}

.minesweeper-header>ul>li>a, .logo-text {
    font-family: sans-serif;
    font-weight: bold;
    font-size: 1.5em;
    color: rgba(0, 0, 0, 0.8);
    text-decoration: none;
    &:hover {
        color: white;
    }
}

.minesweeper-header>ul>li.logo {
    font-size: 1.2em;
    color: rgba(0, 0, 0, 0.8);
    font-weight: bold;
}

.header-left {
    justify-self: start;
}

.header-right {
    justify-self: end;
}
