.newGame {
    text-align: center;
    padding-top: 3em;
}

h3 {
    text-align: center;
}

.message {
    margin-top: 3em;
    text-align: center;
    font-size: 1.5em;
    color: black;
}

.error {
    margin-top: 3em;
    text-align: center;
    font-size: 1.5em;
    color: red;
}

.table {
    width: 80%;
    margin: auto;
}

