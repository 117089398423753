.left-content {
    padding-left: 60px;
    width: 80%;
    margin-left: 0;
    margin-right: auto;
    @media only screen and (max-width: 768px) {
        padding-left: 10px;
        margin-right: 0;
    }
}

.right-content {
    padding-right: 60px;
    width: 80%;
    margin-left: auto;
    margin-right: 0;
    @media only screen and (max-width: 768px) {
        padding-left: 10px;
        margin-left: 0;
    }
}

.blue-section {
    position: absolute;
    text-align: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    background-color: rgb(9, 0, 81);
    z-index: 1;
    width: 60%;
    min-height: 100vh;
    right: 0%;
}

.blue-background {
    position: relative;
    text-align: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: rgb(9, 0, 81);
    z-index: 1;
    width: 100%;
    max-width: 100%;
    min-height: 100vh;
    font-family: Inter, sans-serif;
    transition: background-color 0.5s ease;
}

.project-clear {
    position: relative;
    text-align: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: rgb(250, 253, 254);
    z-index: 1;
    width: 100%;
    max-width: 100%;
    min-height: 100vh;
    font-family: Inter, sans-serif;
    transition: background-color 0.5s ease;
}

.carousel-show {
    opacity: 1;
    transition: opacity 0.5s ease;
}

.carousel-hide {
    opacity: 0;
    transition: opacity 0.5s ease;
}

.project-header {
    font-family: Inter, sans-serif;
    color: white;
    font-size: 32px;
    padding-bottom: 20px;
    transition: font-size 0.2s;
    transform-origin: left;
    &:hover {
        font-size: 40px;
        transition: font-size 0.2s;
    }
}

.white-section {
    text-align: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(0, 0, 0);
    background-color: rgb(250, 253, 254);
    z-index: 1;
    min-height: 100vh;
    @media only screen and (min-width: 769px) {
        position: absolute;
        width: 60%;
    }
}

.white-title {
    font-family: Inter, sans-serif;
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    margin-left: 0;
    margin-right: auto;
    align-items: start;
    transition: font-size 0.2s;
    transform-origin: left;
    &:hover {
        font-size: 28px;
        transition: font-size 0.2s;
    }
    @media only screen and (max-width: 768px) {
        font-size: 14px;
    }
}

.white-body {
    font-family: Inter, sans-serif;
    color: white;
    font-size: 20px;
    font-weight: normal;
    text-align: left;
    align-items: start;
    transition: font-size 0.2s;
    transform-origin: left;
    &:hover {
        font-size: 22px;
        transition: font-size 0.2s;
    }
    @media only screen and (max-width: 768px) {
        font-size: 12px;
    }
}

.black-title {
    font-family: Inter, sans-serif;
    color: black;
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    margin-left: 0;
    margin-right: auto;
    align-items: start;
    transition: font-size 0.2s;
    transform-origin: left;
    &:hover {
        font-size: 28px;
        transition: font-size 0.2s;
    }
    @media only screen and (max-width: 768px) {
        font-size: 14px;
    }
}

.black-body {
    font-family: Inter, sans-serif;
    color: black;
    font-size: 20px;
    font-weight: normal;
    text-align: left;
    align-items: start;
    transition: font-size 0.2s;
    transform-origin: left;
    &:hover {
        font-size: 22px;
        transition: font-size 0.2s;
    }
    @media only screen and (max-width: 768px) {
        font-size: 12px;
    }
}

.education-section {
    text-align: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(0, 0, 0);
    background-color: rgb(250, 253, 254);
    z-index: 1;
    min-height: 100vh;
    @media only screen and (min-width: 769px) {
        position: absolute;
        width: 50%;
    }
}

.section-header {
    font-family: Inter, sans-serif;
    color: black;
    font-size: 26px;
    font-weight: bold;
    text-align: left;
    margin-left: 0;
    margin-right: auto;
    align-items: start;
    transition: font-size 0.2s;
    transform-origin: left;
    &:hover {
        font-size: 32px;
        transition: font-size 0.2s;
    }
}

.section-title {
    font-family: Inter, sans-serif;
    color: black;
    font-size: 24px;
    font-weight: normal;
    text-align: left;
    align-items: end;
    transform-origin: left;
    transition: font-size 0.2s;
    transform-origin: left;
    &:hover {
        font-size: 28px;
        transition: font-size 0.2s;
    }
}

.section-body {
    font-family: Inter, sans-serif;
    color: black;
    font-size: 20px;
    font-weight: normal;
    text-align: left;
    align-items: end;
    transition: font-size 0.2s;
    transform-origin: left;
    &:hover {
        font-size: 24px;
        transition: font-size 0.2s;
    }
}

.section-image {
    padding: 0px;
    width: 100vw;
    min-height: 100vh;
    max-width: 100%;
}

.blue-section-small {
    margin: 0px;
    text-align: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    background-color: rgb(9, 0, 81);
    z-index: 1;
    width: 100%;
    min-height: 50vh;
}

.white-section-small {
    margin: 0px;
    text-align: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    background-color: rgb(250, 253, 254);
    width: 100%;
    min-height: 50vh;
}

.description {
    font-size: 16px;
    font-family: Inter, sans-serif;
    color: rgb(255, 255, 255);
    text-align: left;
    align-items: start;
    margin-left: 0;
    margin-right: auto;
    transition: font-size 0.2s;
    transform-origin: left;
    &:hover {
        font-size: 18px;
        transition: font-size 0.2s;
    }
}

.project-content {
    flex-direction: row;
    display: flex;
}

.project-title {
    font-size: 16px;
    font-family: Inter, sans-serif;
    color: rgb(255, 255, 255);
    text-align: left;
    margin-right: 0;
    transition: font-size 0.2s;
    transform-origin: left;
    &:hover {
        font-size: 28px;
        transition: font-size 0.2s;
    }
}

.left-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
}

.right-column {
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
}

.link {
    font-family: Inter, sans-serif;
    color: rgb(255, 255, 255);
    text-align: left;
    align-items: start;
}

.vertical-line {
    width: 2px;
    min-height: 100%;
    background-color: rgb(250, 253, 254);
}
