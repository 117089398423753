.contact-section {
    margin: 0px;
    padding: 0px;
    text-align: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: rgb(9, 0, 81);
    z-index: 1;
    width: 100%;
    height: 100vh;
    font-family: Inter, sans-serif;
    transition: background-color 0.5s ease;
}

.contact-clear {
    margin: 0px;
    padding: 0px;
    text-align: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: rgb(250, 253, 254);
    z-index: 1;
    width: 100%;
    height: 100vh;
    font-family: Inter, sans-serif;
    transition: background-color 0.5s ease;
}

.contact-button {
    margin: 5px;
    font-family: Inter, sans-serif;
    font-size: 24px;
    color: white;
    border-radius: 30px;
    background: transparent;
    border: 2px solid white;
    width: 150px;
    height: 60px;
    transition: background-color 0.5s ease-out;
    &:hover {
        background: white;
        color: black;
    }
    @media only screen and (max-width: 768px) {
        width: 100px;
        font-size: 20px;
    }
}

.contact-header {
    margin-top: auto;
    font-family: Inter, sans-serif;
    color: white;
    font-size: 32px;
    transition: font-size 0.2s;
    &:hover {
        font-size: 40px;
        transition: font-size 0.2s;
    }
}

.contact-section-small {
    margin: 0px;
    padding: 0px;
    text-align: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: rgb(9, 0, 81);
    z-index: 1;
    width: 100%;
    min-height: 50vh;
}

.update {
    text-align: left;
    font-size: 10px;
    padding: 10px;
    margin-top: auto;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: auto;
}
