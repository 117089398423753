.Notepad {
  padding-top: 4.5em;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.Info {
  color: black;
  padding: 10px;
  text-align: center;
}
  
.Notepad-Title {
  text-align: center;
  padding: 0 15% 0 15%;
  background-color: #f5f5f4;
}
  
.lines {
  background-size: 24px 24px;
  background-image: 
  linear-gradient(#5ec4ff80 0.1em, transparent 0.1em);
  box-sizing: border-box;
  background-color: #f5f5f4;
}
  
.Page {
  margin: 0 15% 0 15%;
  border-left: 3px solid red;
}

.Notepad-Title input {
  border: none;
  outline: none;
  border-left: red 3px solid;
  font-size: 32px;
  font-family: 'Segoe UI';
  padding: 15px 0 15px 5px;
  width: 100%;
  font-weight: bold;
  background-color: #f5f5f4;
}
  
.Page textarea {
  font-size: 18px;
  font-family: 'Segoe UI';
  line-height: 24px;
  padding-left: 5px;
  border: none;
  outline: none;
  width: 100%;
  resize: none;
  background: transparent;
  overflow: hidden;
}
  